<template>
  <div class="responsive-modal" :class="[open ? 'show' : 'hide']">
    <div class="blur" @click="onOutsideClick"></div>
    <div class="responsive-modal-body" :class="{ animate: animate }" :id="id">
      <div class="body-container">
        <div class="close-button-container" @click="onClose">
          <div class="close-button">
            <img class="close-icon" src="@/assets/icons/ClosePopup.svg" />
          </div>
        </div>
        <slot name="modal-content"> </slot>
        <!-- <Loading v-if="loading" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  events: ["onClose"],
  // components: {
  // Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  // },
  props: ["open", "sticky", "id", "loading"],
  data() {
    return {
      isMobile: false,
      animate: false,
    };
  },
  mounted() {
    let windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      this.isMobile = true;
    }
    this.$emit("isMobile", this.isMobile);
    window.onresize = () => {
      let windowWidth = window.innerWidth;
      if (windowWidth < 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      this.$emit("isMobile", this.isMobile);
    };
  },
  methods: {
    onClose() {
      this.animate = false;
      setTimeout(() => {
        this.$emit("onClose");
      }, 300);
    },
    onOutsideClick() {
      if (!this.sticky) {
        this.animate = false;
        setTimeout(() => {
          this.$emit("onClose");
        }, 300);
      }
    },
  },
  watch: {
    open(newValue, oldValue) {
      setTimeout(() => {
        if (newValue) {
          //  document.body.classList.add("no-scroll");
          //document.body.style.position = 'fixed';
          document.body.style.overflow = "hidden";
          //   document.body.style.top = `-${window.scrollY}px`;
          this.animate = true;
        } else {
          // document.body.classList.remove("no-scroll");
          this.animate = false;
          //const scrollY = document.body.style.top;
          //  document.body.style.position = 'static';
          document.body.style.overflow = "visible";

          //      document.body.style.top = '';
          //    window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.blur {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba($color: #0b0b0b, $alpha: 0.5);
  transition: all 0.3s ease;
}
.body-container {
  padding: 5px;
  transition: all 0.3s ease;
  margin-left: 2px;
}
.responsive-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2600;
}
.show {
  display: block;
}
.hide {
  display: none;
}

.no-scroll {
  height: 100vh;
  overflow: hidden;
}

.responsive-modal-body {
  width: 100%;
  max-height: 900px;
  position: fixed;
  z-index: 2000 !important;
  background-color: black !important;
  transition: all 450ms cubic-bezier(0.32, 1, 0.23, 1) 100ms;
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.close-button-container {
  cursor: pointer !important;
  position: relative;
  width: 100%;
  height: 30px;
  transition: all 0.3s ease;
}
.close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  padding: 10px;
}
.close-icon {
  width: 10px;
}

@media only screen and (min-width: 768px) {
  .responsive-modal-body {
    top: 30%;
    left: 35%;
    border-radius: 16px;
    max-height: 400px !important;
    width: 30%;
    border-bottom-left-radius: 24px !important;
    border-bottom-right-radius: 24px !important;
  }
  .animate {
    transform: translateY(-30px);
  }
  .body-container {
    padding: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .responsive-modal-body {
    bottom: 0px;
  }
  .animate {
    bottom: 0% !important;
  }
}
</style>
